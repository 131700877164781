import React from 'react';
import '../styles/copy.css'

function Copy() {
    return (
        <div className='copy'>
            <p>&copy; 2022 - Eletrotekcell Fortaleza</p>
        </div>
    )
}

export default Copy